import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: "", redirectTo: "", pathMatch: "full" },
  {
    path: "liquidarprovisiones",
    loadChildren: "./screen/liquidar-provisiones/liquidar-provisiones.module#LiquidarProvisionesModule",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
